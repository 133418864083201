<template>
  <div class="circle-container">
    <div class="circle" :style="{ borderColor: borderColor }">
      <div class="circle-text">{{ innerValue + ' ' + complemento}}</div>
    </div>
    <div class="title-container">
      <p class="title">{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    borderColor: {
      type: String,
      default: '#000000'
    },

     complemento: {
      type: String,
      default: ''
    },
    innerValue: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      default: 'Title'
    }
  }
};
</script>

<style scoped>
.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 8px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: 4px 12px 12px rgba(0, 0, 0, 0.1); /* Adicione esta linha para a elevação */

}

.circle-text {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.title-container {
  text-align: center;
}

.title {
  font-size: 16px !important;
  font-weight: bold;
  color: #333333;
}
</style>
